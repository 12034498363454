// Moore neighborhood
export const operations = [
    [-1, -1],  // NW
    [-1, 0],   // N
    [-1, 1],   // NE
    [0, 1],    // E
    [1, 1],    // SE
    [1, 0],    // S
    [1, -1],   // SW
    [0, -1],   // W
];